import { get, makeResource, post } from '@42.nl/spring-connect';
import _ from 'lodash';
import { QueryParams, search } from '../filters/Search';
import { getPeriodSequence } from '../periods/PeriodService';
import Reference from '../references/Reference';
import { getReferenceById } from '../references/ReferenceService';
import AcademicSession from './AcademicSession';
import AcademicYear from './AcademicYear';
import Group from './canonical/Group';
import GroupModule from './canonical/GroupModule';
import Module from './canonical/Module';
import ClientOffering, { getTimeBlocks } from './canonical/Offering';
import { GroupStudy } from './GroupStudy';
import Product, { ProductTypeEnum } from './Product';
import { Rule } from './Rule';

const baseUrl = '/api/module';

export default class SimpleModule
  extends makeResource<SimpleModule>(baseUrl)
  implements Product
{
  id!: number;
  data!: Module;
  productType!: string;
  offering?: Offering[];

  static groups(module: SimpleModule): Promise<GroupStudy[]> {
    if (module.id === undefined) {
      return Promise.resolve([]);
    }

    return get(`${baseUrl}/${module.id}/groups`);
  }

  static years(code: string): Promise<AcademicYear[]> {
    return get(`${baseUrl}/${code}/years`);
  }

  static async import(module: SimpleModule) {
    return post(`${baseUrl}/${module.id}/import`, null);
  }

  static rules(module: SimpleModule): Promise<Rule[]> {
    return get(`${baseUrl}/${module.id}/rules`);
  }

  static async search(queryParams: QueryParams) {
    return search<SimpleModule>(ProductTypeEnum.MODULE, baseUrl, queryParams);
  }
}

export class Offering {
  period!: AcademicYear;
  blocks?: Block[];
}

export class Block {
  externalId!: string;
  code?: string;
}

export type ModuleRow = {
  structure: GroupModule;
  module?: SimpleModule;
};

export async function loadModuleRows(group: Group): Promise<ModuleRow[]> {
  const page = await SimpleModule.page({
    group: group.uid,
    year: group.year.id,
    size: 999
  });

  return (group.modules || [])
    .map((structure) => buildRow(structure, page.content))
    .filter((row) => row.module);
}

function buildRow(structure: GroupModule, modules: SimpleModule[]): ModuleRow {
  return {
    structure,
    module: modules.find(
      (module) => module.data?.code === structure.module?.code
    )
  };
}

export function sortModules(
  rows: ModuleRow[] | undefined,
  phases: Reference[],
  periods: AcademicSession[],
  timeBlocks: Reference[]
): ModuleRow[] {
  // Set default values of sorting properties so that nulls are sorted first
  const sortedRows = _(rows)
    .map((row) => ({
      row,
      code: _.get(row, 'module.code', ''),
      sequence: _.get(row, 'structure.sequence', -1),
      phaseSequence: _.get(
        getReferenceById(phases, row.structure.phase),
        'sequence',
        -1
      ),
      periodSequence: getMinPeriodSequence(row.structure?.offerings, periods),
      blockSequence: getMinBlockSequence(row.structure?.offerings, timeBlocks)
    }))
    .orderBy([
      'sequence',
      'phaseSequence',
      'periodSequence',
      'blockSequence',
      'code'
    ])
    .value();

  return sortedRows.map((row) => row.row);
}

function getMinPeriodSequence(
  offerings: ClientOffering[] | undefined,
  periods: AcademicSession[]
) {
  const sequences = _(offerings)
    .map((offering) => getPeriodSequence(periods, offering.period?.id))
    .sort()
    .value();

  return sequences.length > 0 ? sequences[0] : periods.length;
}

function getMinBlockSequence(
  offerings: ClientOffering[] | undefined,
  timeBlocks: Reference[]
) {
  const sequences = getTimeBlocks(offerings, timeBlocks)
    .map((reference) => reference?.sequence)
    .filter((sequence) => sequence !== null && sequence !== undefined)
    .sort();

  return sequences[0] || timeBlocks.length;
}
