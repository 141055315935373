import { useMemo } from 'react';
import Tab from '../../Tab';
import { useTabs } from '../TabsContext';
import { useActiveMainTab } from './useActiveMainTab';

export function useSubTabs(): Tab[] {
  const { sortedTabs = [] } = useTabs();
  const activeMainTab = useActiveMainTab();

  return useMemo(() => {
    if (!activeMainTab) {
      return [];
    }
    return sortedTabs.filter((tab) => {
      return (
        tab.entityType === activeMainTab.entityType &&
        !tab.isParent() &&
        tab.parentTabs?.some(
          (parentTab) => parentTab.id === activeMainTab.id
        ) &&
        tab.id !== activeMainTab.id
      );
    });
  }, [sortedTabs, activeMainTab]);
}
