import { urlBuilder } from '@42.nl/react-url';
import { isEmpty, split } from 'lodash';
import { parse } from 'query-string';
import { NavLink, useLocation } from 'react-router-dom';
import { Nav } from 'reactstrap';
import { getText } from '../../../../../../i18n/Text';
import { useMainTabs } from '../../../../../../tabs/context/hooks/useMainTabs';
import Tab from '../../../../../../tabs/Tab';

type MainTabsProps = {
  closeMobileMenu: () => void;
};

export default function MainTabs({ closeMobileMenu }: Readonly<MainTabsProps>) {
  const tabs = useMainTabs();

  return (
    <Nav
      navbar
      className="main-nav flex-row"
    >
      {tabs?.map((tab) => (
        <MainTab
          key={tab.id}
          tab={tab}
          onClick={closeMobileMenu}
        />
      ))}
    </Nav>
  );
}

type MainTabProps = {
  tab: Tab;
  onClick: () => void;
};

function MainTab({ tab, onClick }: Readonly<MainTabProps>) {
  const baseUrl = tab.getUrl() || '';

  if (isEmpty(baseUrl)) {
    return null;
  }

  return (
    <EntityTab
      tab={tab}
      active={tab.isActiveMain}
      baseUrl={baseUrl}
      onClick={onClick}
    />
  );
}

type EntityTabProps = {
  tab: Tab;
  active: boolean;
  baseUrl: string;
  onClick: () => void;
};

function EntityTab({
  tab,
  active,
  baseUrl,
  onClick
}: Readonly<EntityTabProps>) {
  const location = useLocation();

  function getUrl() {
    const searchParams = parse(location.search);
    const year = Array.isArray(searchParams.year)
      ? searchParams.year[0]
      : searchParams.year;
    const params = { ...toQueryParams(tab, year) };

    return urlBuilder({
      url: baseUrl,
      pathParams: params,
      queryParams: params
    });
  }

  return (
    <NavLink
      to={getUrl()}
      onClick={onClick}
      className={() => (active ? 'active' : '')}
      replace
    >
      {getText(tab.labels, tab.name)}
    </NavLink>
  );
}

export function toQueryParams(tab: Tab | undefined, year: string | null) {
  const params = {
    year: year || ''
  };

  if (tab?.hasFilter()) {
    const [name, value] = split(tab.filter, '=').map((value_) => value_.trim());

    if (!isEmpty(name)) {
      const values = split(value, ',').map((value_) => value_.trim());
      return { [name]: values, ...params };
    }
  }

  return params;
}

export type MainTabParams = {
  mainTab?: string;
};
