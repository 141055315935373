import Product from '../../../types/Product';
import Widget, { getFields } from '../../Widget';
import { WidgetItem } from '../../WidgetItem/WidgetItem';

type Props = {
  widget: Widget;
  product: Product;
  expanded: boolean;
  expandable: boolean;
};

export function ContainerWidget({
  widget,
  product,
  expanded,
  expandable
}: Readonly<Props>) {
  return (
    <>
      {getFields(widget).map((field, index) => (
        <WidgetItem
          key={`field-${index}`}
          field={field}
          type={widget.type}
          hideHeader={widget.hideHeader}
          product={product}
          expanded={expanded}
          expandable={expandable}
        />
      ))}
    </>
  );
}
