import { ProductDetailQueryParams } from '../../types/Product';
import { getYearService } from '../../years/YearService';

export function defaultQualificationDetailQueryParams(): ProductDetailQueryParams {
  return {
    year: getYearService().getCurrentYear().externalId,
    tab: 'info',
    mainTab: 'qualification'
  };
}
