import { createContext, ReactNode, useContext, useMemo } from 'react';
import { useQuery } from 'react-query';
import Tab from '../Tab';

const TabsContext = createContext<TabsContextType | undefined>(undefined);

interface TabsContextType {
  sortedTabs: Tab[] | undefined;
  isLoading: boolean;
  error: unknown;
}

interface TabsProviderProps {
  children: ReactNode;
}

export function TabsProvider({ children }: Readonly<TabsProviderProps>) {
  const {
    data: sortedTabs,
    isLoading,
    error
  } = useQuery(Tab.QUERY_KEY, async () => {
    return await Tab.findAll();
  });
  const value = useMemo(
    () => ({ sortedTabs, isLoading, error }),
    [sortedTabs, isLoading, error]
  );

  return <TabsContext.Provider value={value}>{children}</TabsContext.Provider>;
}

export function useTabs() {
  const context = useContext(TabsContext);
  if (!context) {
    throw new Error('useTabs must be used within a TabsProvider');
  }
  return context;
}
