import React, { useRef, useState } from 'react';
import { Button } from '@42.nl/ui';
import { useTranslation } from 'react-i18next';
import { useReactToPrint } from 'react-to-print';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

type Props = {
  children: React.ReactNode;
};

export function PrintButton({ children }: Readonly<Props>) {
  const [modal, setModal] = useState(false);

  const { t } = useTranslation('translation');

  const printRef = useRef<HTMLDivElement>(null);
  const print = useReactToPrint({
    content: () => printRef.current
  });

  function toggle() {
    setModal(!modal);
  }

  return (
    <>
      <Button onClick={toggle}>{t('PRINT.BUTTON')}</Button>
      <Modal
        isOpen={modal}
        size="xl"
      >
        <ModalHeader toggle={toggle}>{t('PRINT.MODAL_HEADER')}</ModalHeader>
        <ModalFooter>
          <Button onClick={toggle}>{t('CANCEL')}</Button>
          <Button onClick={print}>{t('PRINT.BUTTON')}</Button>
        </ModalFooter>
        <ModalBody>
          <div
            ref={printRef}
            className="m-1"
          >
            {children}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}
