import { Page, post } from '@42.nl/spring-connect';
import {
  each,
  find,
  head,
  isArray,
  isEmpty,
  isString,
  last,
  set,
  uniq
} from 'lodash';
import queryString from 'query-string';
import { MainTabParams } from '../components/AppFrame/components/TopBar/components/MainTabs/MainTabs';
import { Paged } from '../components/PaginationBar/Paged';
import { getDefaultSort } from '../components/SortSelector/SortSelector';
import Product, { ProductListQueryParams, ProductType } from '../types/Product';
import { getYearService } from '../years/YearService';
import Filter from './Filter';
import { FilterOperator } from './FilterOperator';
import { getFilterService } from './FilterService';

const OFFERING_PREFIX = 'offering';
const OFFERING_DOT_PREFIX = `${OFFERING_PREFIX}.`;
const OFFERINGS_KEY = `${OFFERING_PREFIX}s`;
const OFFERING_VALUES_KEY = `${OFFERING_PREFIX}Values`;
const OFFERING_VALUES_DOT_PREFIX = `${OFFERING_VALUES_KEY}.`;
const VALUES_DOT_PREFIX = 'values.';

interface SearchPayload {
  text?: string;
  values?: object;
  offerings?: string[];
  offeringValues: object;
}

interface SearchParams {
  page: number;
  size: number;
  sort: string;
  year: string;
}

export type QueryParams = ProductListQueryParams & Paged & MainTabParams;

export function defaultQueryParams(): QueryParams {
  return {
    text: '',
    year: getYearService().getCurrentYear().externalId,
    page: 1,
    sort: getDefaultSort().sort,
    size: 10
  };
}

export function search<T extends Product>(
  productType: ProductType,
  baseUrl: string,
  queryParams: QueryParams
): Promise<Page<T>> {
  const url = generateUrl(queryParams, baseUrl);
  const payload = generatePayload(productType, queryParams);

  return post(url, payload);
}

export function generateUrl(
  searchParam: SearchParams,
  baseUrl: string
): string {
  return (
    `${baseUrl}/search?` +
    queryString.stringify({
      page: searchParam.page,
      size: searchParam.size,
      sort: searchParam.sort,
      year: searchParam.year
    })
  );
}

function generatePayload(
  productType: ProductType,
  queryParams: QueryParams
): SearchPayload {
  const payload: SearchPayload = {
    text: '',
    values: {},
    offerings: [],
    offeringValues: {}
  };

  const filters = getFilterService().getFilters(productType);

  each(queryParams, (value, key) => {
    if (key === 'text' && isString(value)) {
      set(payload, key, value);
    } else if (!isEmpty(value)) {
      const filter = find(filters, { name: key });
      if (filter) {
        const values = isArray(value) ? uniq(value) : [`${value}`];

        if (key === OFFERING_PREFIX) {
          set(payload, OFFERINGS_KEY, values);
        } else if (key.startsWith(OFFERING_DOT_PREFIX)) {
          const newKey = key.slice(OFFERING_DOT_PREFIX.length);
          set(payload, `${OFFERING_VALUES_DOT_PREFIX + newKey}`, values);
        } else {
          set(
            payload,
            `${VALUES_DOT_PREFIX + key}`,
            generateFilter(filter, values)
          );
        }
      }
    }
  });

  return payload;
}

function generateFilter(filter: Filter, values: string[]) {
  const { valueType, exact } = filter;

  if (!exact && (valueType === 'NUMBER' || valueType === 'DATE')) {
    return [
      {
        operator: FilterOperator.GREATER_OR_EQUAL,
        valueType,
        values: [head(values)]
      },
      {
        operator: FilterOperator.LESS_OR_EQUAL,
        valueType,
        values: [last(values)]
      }
    ];
  }

  return [
    {
      operator: FilterOperator.EQUAL_TO,
      valueType,
      values
    }
  ];
}
