import { uniqueId } from 'lodash';
import { QuickviewItem } from '../../../components/Quickview/components/QuickviewItem';
import { getText } from '../../../i18n/Text';
import Reference, { displayName } from '../../../references/Reference';
import { useReferences } from '../../../references/useReferences';
import Appraisal, { getAppraisals } from '../../../types/canonical/Appraisal';
import Product from '../../../types/Product';
import Widget, { WidgetField, getFields, getValue } from '../../Widget';
import { WidgetTitle } from './WidgetTitle';

type Props = {
  widget: Widget;
  product: Product;
};

export function AppraisalsWidget({ widget, product }: Readonly<Props>) {
  const appraisals = getAppraisals(product?.data?.assessments, widget.filter);

  return (
    <>
      <WidgetTitle widget={widget} />
      {appraisals.map((appraisal, index) => (
        <AppraisalQuickview
          key={`appraisal-${index}`}
          widget={widget}
          appraisal={appraisal}
          year={product.data.year.id}
        />
      ))}
    </>
  );
}

type QuickviewProps = {
  widget: Widget;
  appraisal: Appraisal;
  year: string;
};

function AppraisalQuickview({
  widget,
  appraisal,
  year
}: Readonly<QuickviewProps>) {
  const children = getAppraisals(
    appraisal.assessment?.assessments,
    widget.filter
  );

  return (
    <section className="quickview quickview-widget d-flex flex-column cursor-default">
      <div className="quickview__main d-flex flex-row align-items-center">
        <div className="d-flex flex-column flex-grow-1">
          <div>
            <span className="element-name">
              {getText(appraisal.assessment?.names)}
            </span>
          </div>
          <div className="quickview__main-attributes my-2 flex-row flex-wrap">
            {getFields(widget).map((field) => (
              <AppraisalItem
                key={field.id}
                field={field}
                appraisal={appraisal}
                year={year}
              />
            ))}
          </div>
        </div>
      </div>

      {appraisal.description ? (
        <div className="quickview__extra">
          <div className="quickview__extra__summary">
            {appraisal.description}
          </div>
        </div>
      ) : null}

      {children.length > 0 ? (
        <div className="pt-2">
          {children.map((assessment) => (
            <div
              key={uniqueId('aq-nested-')}
              className="quickview__extra"
            >
              <AppraisalQuickview
                widget={widget}
                appraisal={assessment}
                year={year}
              />
            </div>
          ))}
        </div>
      ) : null}
    </section>
  );
}

type ItemProps = {
  field: WidgetField;
  appraisal: Appraisal;
  year: string;
};

function AppraisalItem({ field, appraisal, year }: Readonly<ItemProps>) {
  const { references } = useReferences(field.referenceType, year);
  const value = getAppraisalValue(field, appraisal, references);

  if (!value && field.hideEmpty) {
    return <div></div>;
  }

  return (
    <QuickviewItem
      displayName={getText(field.labels)}
      tooltip={getText(field.tooltips, undefined)}
      value={value || 'N/A'}
    />
  );
}

function getAppraisalValue(
  field: WidgetField,
  appraisal: Appraisal,
  references: Reference[]
) {
  switch (field.name) {
    case 'criteria':
      return displayName(appraisal.criteria, references);
    case 'duration':
      return appraisal.assessment?.duration;
    case 'evaluation':
      return displayName(appraisal.assessment?.evaluation, references);
    case 'type':
      return displayName(appraisal.assessment?.type, references);
    case 'weighting':
      return appraisal.weighting;
  }

  let value = getValue(field, appraisal.values || [], references);
  if (!value) {
    value = getValue(field, appraisal.assessment?.values || [], references);
  }
  return value;
}
