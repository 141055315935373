import { useTranslation } from 'react-i18next';
import { LanguageLabel } from '../i18n';
import Tab from '../tabs/Tab';
import Product from '../types/Product';
import { WidgetDetail } from '../widgets/WidgetDetail/WidgetDetail';

type Props = {
  tabs: Tab[];
  product: Product;
};

export function ProductPrint({ tabs, product }: Props) {
  const { t } = useTranslation('translation');

  return (
    <>
      <h1 className="details__content__main-title">
        <span className="pe-1">({product.data.code})</span>
        <LanguageLabel names={product.data.names} />
      </h1>

      <h2 className="mt-3">
        {t('YEAR')}: {product.data.year.code}
      </h2>

      {tabs.map((tab) => (
        <WidgetDetail
          key={tab.id}
          tabId={tab.id}
          product={product}
          expanded={true}
          includeWidget={(widget) => widget.showOnPrint}
        />
      ))}
    </>
  );
}
