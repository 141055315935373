import React, { useState } from 'react';
import { isEmpty } from 'lodash';
import { getText } from '../../../i18n/Text';
import Product from '../../../types/Product';
import Widget from '../../../widgets/Widget';
import { WidgetPanel } from '../../../widgets/WidgetPanel/WidgetPanel';
import { Expander } from '../../Expander/Expander';
import { QuickviewDetailsLink } from '../components/QuickviewDetailsLink';

type Props = {
  product: Product;
  widgets: Widget[];
  seeMoreText: string;
  linkToDetail: string;
  children?: React.ReactNode;
};

export function ProductQuickview({
  product,
  widgets,
  seeMoreText,
  linkToDetail,
  children
}: Readonly<Props>) {
  const [collapsed, setCollapsed] = useState(true);

  const summaryWidgets = widgets.filter((widget) => widget.type !== 'SIDEBAR');
  const detailWidgets = widgets.filter((widget) => widget.type === 'SIDEBAR');

  const name = getText(product.data.names);

  const toggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <section
      className="quickview quickview-product d-flex flex-column"
      onClick={toggle}
    >
      <div className="quickview__main d-flex flex-row align-items-center">
        <div className="d-flex flex-column flex-grow-1 pe-md-4">
          <div>
            <span className="element-name pe-1 font-weight-normal">
              ({product.data.code})
            </span>
            <span className="element-name">{name}</span>
          </div>
          <div className="d-flex mt-2">{children}</div>
        </div>
        <Expander collapsed={collapsed} />
      </div>
      {
        // Note: the fontSize (and possibly other styles) need to be set inline
        // to prevent re-renders and other weird bugs
      }
      {!collapsed ? (
        <div
          style={{ fontSize: '1.5rem' }}
          className="quickview__extra"
        >
          <div className="quickview__extra__summary flex-grow-1 d-flex flex-column">
            {!isEmpty(summaryWidgets) ? (
              <div className="flex-grow-1">
                {summaryWidgets.map((widget) => (
                  <WidgetPanel
                    key={widget.id}
                    widget={widget}
                    product={product}
                    expanded={false}
                    expandable={false}
                  />
                ))}
              </div>
            ) : null}
            <QuickviewDetailsLink
              linkToDetail={linkToDetail}
              seeMoreText={seeMoreText}
            />
          </div>
          {!isEmpty(detailWidgets) ? (
            <div className="quickview__extra__details">
              {detailWidgets.map((widget) => (
                <WidgetPanel
                  key={widget.id}
                  widget={widget}
                  product={product}
                  expanded={false}
                  expandable={false}
                />
              ))}
            </div>
          ) : null}
        </div>
      ) : null}
    </section>
  );
}
