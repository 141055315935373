import { useQuery } from 'react-query';
import { Loader } from '../../../components';
import ModuleListItem from '../../../modules/ModuleList/ModuleListItem';
import { getPeriodService } from '../../../periods/PeriodService';
import { ReferenceType } from '../../../references/Reference';
import { useReferences } from '../../../references/useReferences';
import Group from '../../../types/canonical/Group';
import { loadModuleRows, sortModules } from '../../../types/SimpleModule';

type Props = {
  group: Group;
  year: string;
};

export function GroupModulesList({ group, year }: Readonly<Props>) {
  const state = useQuery(
    ['groupModules', { groupUid: group.uid, yearId: group.year.id }],
    () => loadModuleRows(group)
  );
  const { references: timeBlocks } = useReferences(
    ReferenceType.TIME_BLOCK,
    year
  );
  const { references: phases } = useReferences(ReferenceType.PHASE, year);
  const periods = getPeriodService().getPeriods(year);

  return (
    <Loader state={state}>
      {(rows) => (
        <>
          {sortModules(rows, phases, periods, timeBlocks).map((row, index) =>
            row.module !== undefined ? (
              <ModuleListItem
                key={index}
                module={row.module}
              />
            ) : null
          )}
        </>
      )}
    </Loader>
  );
}
