import { Layout as AntdLayout } from 'antd';
import LinkLabel from '../../../LinkLabel/LinkLabel';

const { Footer: AntdFooter } = AntdLayout;

const linkLabels = [
  { labelKey: 'FOOTER.DISCLAIMER.LABEL', urlKey: 'FOOTER.DISCLAIMER.URL' },
  { labelKey: 'FOOTER.PRIVACY.LABEL', urlKey: 'FOOTER.PRIVACY.URL' },
  { labelKey: 'FOOTER.COOKIES.LABEL', urlKey: 'FOOTER.COOKIES.URL' },
  { labelKey: 'FOOTER.COPYRIGHT.LABEL', urlKey: 'FOOTER.COPYRIGHT.URL' }
];

export default function Footer() {
  return (
    <AntdLayout style={{ marginTop: '50px' }}>
      <AntdFooter
        id="footer"
        className="font-base-normal"
        style={{
          display: 'flex',
          width: '100%',
          maxWidth: '1170px',
          margin: 'auto'
        }}
      >
        {linkLabels.map((link) => (
          <LinkLabel
            key={link.labelKey}
            labelKey={link.labelKey}
            urlKey={link.urlKey}
            namespace="footer"
          />
        ))}
      </AntdFooter>
    </AntdLayout>
  );
}
